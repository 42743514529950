<template>
  <div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row align-items-center centered justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card card-login">
              <div class="card-body p-4">
                <div>
                  <b-alert
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >
                  <b-form @submit.prevent="tryToLogIn">
                    <b-form-group
                      id="input-group-1"
                    >
                      <label for="input-1">Username</label>
                      <div class="position-relative pt-1 pb-2">
                        <b-form-input
                          id="input-1"
                          v-model="username"
                          type="text"
                          placeholder="Enter Username"
                          :class="{ 'is-invalid': submitted && v$.username.$error }"
                        ></b-form-input>
                        <div
                          v-if="submitted && v$.username.$invalid"
                          class="invalid-feedback"
                        >
                          Username is required.
                        </div>
                      </div>
                    </b-form-group>

                    <b-form-group id="input-group-2">
                      <label for="input-2">Password</label>
                      <div class="position-relative pt-1 pb-2">
                        <b-form-input
                          id="input-2"
                          v-model="password"
                          :type="!showPassword ? 'password' : 'text'"
                          placeholder="Enter password"
                          :class="{
                          'is-invalid': submitted && v$.password.$error
                        }"
                        ></b-form-input>
                        <div
                          v-if="submitted && v$.password.$invalid"
                          class="invalid-feedback"
                        >
                          Password is required.
                        </div>
                      </div>
                    </b-form-group>
                   
                    <div class="pt-4 mt-4 text-center">
                      <b-button
                        type="submit"
                        variant="primary"
                        class="w-sm btn-login"
                        >Login</b-button
                      >
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { defineComponent } from "vue";
import { required } from "@vuelidate/validators";
import { mapState, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";

export default defineComponent({
  name: "Login",
  setup() {
    return { 
      v$: useVuelidate()
    };
  },
  data() {
    return {
      username: "superadmin",
      password: "Welcome123!",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      showPassword: false,
    };
  },
  validations() {
    return {
      username: {
        required
      },
      password: {
        required
      },
    };
  },
  computed: {
    ...mapState("authfack", ["status"])
  },
  created() {
    if (this.status) {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapActions("authfack", ["login"]),
    tryToLogIn() {
      this.submitted = true;
      this.v$.$validate();
      if (this.v$.$invalid || (this.status && this.status.loggeduser)) {
        return;
      } else {
        const { username, password } = this;
        if (username && password) {
          this.login({
            username,
            password,
          });
        }
      }
    },
  },
});
</script>

<style lang="scss" module></style>